<template>
  <transition name="fade-bottom-2x">
    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
      <vx-card>
        <div class="pt-2 pb-2 pr-2 pl-2" align="right">
          <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-x" @click="closeCompanyDetail()"></vs-button>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4 mb-base">
            <vx-card class="overflow-hidden dark-theme">
              <div class="p-6 dialog">
                <ul class="vx-timeline">
                  <li>
                    <div class="timeline-icon bg-primary">
                      <feather-icon icon="PlusIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info">
                      <p class="font-semibold">{{ $t('vue.name') }}</p>
                      <span class="activity-desc">{{ detail.company.name }}</span>
                    </div>
                    <small v-if="detail.company.street || detail.company.city" class="activity-e-time">
                      {{ detail.company.street }} {{ detail.company.city }}
                    </small>
                    <br />
                    <small class="activity-e-time">{{ detail.company.zipcode }} {{ detail.company.country }}</small
                    ><br />
                  </li>
                  <li>
                    <div class="timeline-icon bg-warning">
                      <feather-icon icon="LinkIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info">
                      <p class="font-semibold">{{ $t('vue.url') }}</p>
                      <span class="activity-desc">{{ detail.company.url }}</span>
                    </div>
                  </li>
                  <li>
                    <div class="timeline-icon bg-danger">
                      <feather-icon icon="PhoneIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info">
                      <p class="font-semibold">{{ $t('vue.phone') }}</p>
                      <span class="activity-desc">{{ detail.company.phone }}</span>
                    </div>
                  </li>
                  <li v-if="detail.company.createdByUser">
                    <div class="timeline-icon bg-danger">
                      <feather-icon icon="UserIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info">
                      <p class="font-semibold">{{ $t('vue.createdBy') }}</p>
                      <span class="activity-desc">{{ detail.company.createdByUser.name }}</span>
                    </div>
                    <small class="activity-e-time">{{ detail.company.createdByUser.email }}</small
                    ><br />
                  </li>
                  <li>
                    <div class="timeline-icon bg-danger">
                      <feather-icon icon="FlagIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info" v-if="detail.company.utm_source">
                      <p class="font-semibold">utm</p>
                      <social-icons :name="getUtmSource(detail.company.utm_source)"></social-icons>
                    </div>
                    <div class="timeline-info" v-if="detail.company.utm_campaign">
                      <p class="font-semibold">{{ detail.company.utm_campaign }}</p>
                      <p>
                        <small v-if="detail.company.utm_medium" class="activity-e-time">{{ detail.company.utm_medium }} </small>
                      </p>
                      <p>
                        <small v-if="detail.company.utm_term" class="activity-e-time">{{ detail.company.utm_term }} </small>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4 mb-base" v-if="!loading">
            <vx-card class="overflow-hidden dark-theme">
              <div class="p-6 dialog" v-if="detail.affiliateCompany">
                <ul class="vx-timeline" v-if="detail.affiliateCompany">
                  <li>
                    <div class="timeline-icon bg-primary">
                      <feather-icon icon="PlusIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info">
                      <p class="font-semibold">{{ $t('vue.affiliateCompany') }}</p>
                      <span class="activity-desc">{{ detail.affiliateCompany.name }}</span>
                    </div>
                    <small class="activity-e-time">{{ detail.affiliateCompany.street }} {{ detail.affiliateCompany.city }}</small
                    ><br />
                    <small class="activity-e-time">{{ detail.affiliateCompany.zipcode }} {{ detail.affiliateCompany.country }}</small
                    ><br />
                  </li>
                  <li>
                    <div class="timeline-icon bg-warning">
                      <feather-icon icon="LinkIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info">
                      <p class="font-semibold">{{ $t('vue.url') }}</p>
                      <span class="activity-desc">{{ detail.affiliateCompany.url }}</span>
                    </div>
                  </li>
                  <li>
                    <div class="timeline-icon bg-danger">
                      <feather-icon icon="PhoneIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info">
                      <p class="font-semibold">{{ $t('vue.phone') }}</p>
                      <span class="activity-desc">{{ detail.affiliateCompany.phone }}</span>
                    </div>
                  </li>
                  <li v-if="detail.affiliateCompany.createdByUser">
                    <div class="timeline-icon bg-danger">
                      <feather-icon icon="UserIcon" svgClasses="text-white stroke-current w-5 h-5" />
                    </div>
                    <div class="timeline-info">
                      <p class="font-semibold">{{ $t('vue.createdBy') }}</p>
                      <span class="activity-desc">{{ detail.affiliateCompany.createdByUser.name }}</span>
                    </div>
                    <small class="activity-e-time">{{ detail.affiliateCompany.createdByUser.email }}</small
                    ><br />
                  </li>
                </ul>
              </div>
              <div class="p-6 dialog" v-else>
                <h2>No Affiliate</h2>
              </div>
            </vx-card>
          </div>
        </div>
      </vx-card>
    </div>
  </transition>
</template>
<script>
import 'firebase/functions'
//import firebase from 'firebase/app'
import SocialIcons from '@/components/icons/SocialIcons.vue'
export default {
  components: {
    SocialIcons
  },
  data() {
    return {
      showCompanyDetail: false,
      detail: {
        company: {},
        affiliateCompany: {},
        affiliatePartner: {}
      },
      loading: false
    }
  },
  created() {
    const vm = this
    vm.$serverBus.$on('get-company-detail', async (company) => {
      vm.loading = true
      vm.showCompanyDetail = true
      vm.detail = {
        company: {},
        affiliateCompany: null,
        affiliatePartner: null
      }
      vm.detail.company = company
      const cp = {
        id: company.id,
        createdBy: company.createdBy
      }
      if (company.affiliateCompany) {
        cp.affiliateCompany = company.affiliateCompany
      }
      if (company.affiliatePartner) {
        cp.affiliatePartner = company.affiliatePartner
      }
      vm.$vs.loading()
      //await firebase.functions().useFunctionsEmulator('http://localhost:5001')
      const getCompanyDetail = this.$functions.httpsCallable('getCompanyDetail')
      const res = await getCompanyDetail({ company: cp })
      if (res && res.data && res.data.detail) {
        if (res.data.detail.company && res.data.detail.company.createdByUser) {
          vm.detail.company.createdByUser = res.data.detail.company.createdByUser
        }
        if (res.data.detail.affiliateCompany) {
          vm.detail.affiliateCompany = res.data.detail.affiliateCompany
        } else {
          vm.detail.affiliateCompany = null
        }
        if (res.data.detail.affiliatePartner) {
          vm.detail.affiliateCompany = res.data.detail.affiliateCompany
        }
      }
      vm.$vs.loading.close()
      vm.loading = false
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('get-company-detail')
  },
  methods: {
    getUtmSource(value) {
      let source = value || 'direct'
      source.toLowerCase()
      source = source === 'google' || source === 'facebook' || source === 'linkedin' || source === 'bing' || source === 'mail' ? source : 'direct'
      return source
    },
    checkUtmSourceIcon(value) {
      let source = value || ''
      source = source.toLowerCase()
      return source === 'google' || source === 'facebook' || source === 'linkedin' || source === 'bing' || source === 'mail'
    },
    closeCompanyDetail() {
      this.showCompanyDetail = false
      this.$serverBus.$emit('close-company-detail')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/components/vxTimeline.scss';

.bg-gray {
  background-color: gray;
}
.bg-lightGray {
  background-color: #f2f2f2;
}

.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}
.vs-list--header,
.vs-list--item {
  overflow-wrap: anywhere;
}

.vs-image {
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
}
.company-image {
  display: block;
  margin-top: 20px;
  margin-right: 20px;
}
.company-image .con-vs-image {
  border-radius: 0px !important;
}
.company-image .vs-image--img {
  background-size: cover;
  border-radius: 0px !important;
  min-width: 10px;
}
.vs-image .con-vs-image {
  overflow: visible;
}
.vs-image--img {
  background: no-repeat;
}
.dark-theme {
  background: rgba(59, 134, 247, 0.2);
}
</style>
