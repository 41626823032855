var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-2/3 md:w-1/2 lg:w-2/3 xl:w-2/3 mb-base",
      },
      [
        _c(
          "vs-popup",
          {
            attrs: {
              title: "Extend Trial Period",
              active: _vm.showExtendTrialPeriod,
              width: "800px",
            },
            on: {
              "update:active": function ($event) {
                _vm.showExtendTrialPeriod = $event
              },
            },
          },
          [
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("vue.confirmQuestion")) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "trial-container" }, [
              _c("div", { staticClass: "trial-container-info" }, [
                _c("div", { staticClass: "trial-container-info-header" }, [
                  _vm._v("Current"),
                ]),
                _c("div", [
                  _vm._v("\n            Payment Status: "),
                  _c("span", { staticClass: "trial-container-info-current" }, [
                    _vm._v(_vm._s(_vm.currentPaymentStatus)),
                  ]),
                ]),
                _c("div", [
                  _vm._v("\n            Plan Type: "),
                  _c("span", { staticClass: "trial-container-info-current" }, [
                    _vm._v(_vm._s(_vm.currentPlanType)),
                  ]),
                ]),
                _c("div", [
                  _vm._v("\n            Subscription Plan Id: "),
                  _c("span", { staticClass: "trial-container-info-current" }, [
                    _vm._v(_vm._s(_vm.currentSubscriptonPlanId)),
                  ]),
                ]),
                _c("div", [
                  _vm._v("\n            Expiry Date: "),
                  _c("span", { staticClass: "trial-container-info-current" }, [
                    _vm._v(_vm._s(_vm.currentExpiryDate)),
                  ]),
                ]),
                _c("div", [
                  _vm._v("\n            Claims: "),
                  _c("span", { staticClass: "trial-container-info-current" }, [
                    _vm._v(_vm._s(_vm.currentClaims)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "trial-container-info" }, [
                _c("div", { staticClass: "trial-container-info-header" }, [
                  _vm._v("New"),
                ]),
                _c("div", [
                  _vm._v("\n            Payment Status: "),
                  _c("span", { staticClass: "trial-container-info-new" }, [
                    _vm._v(_vm._s(_vm.newPaymentStatus)),
                  ]),
                ]),
                _c("div", [
                  _vm._v("\n            Plan Type: "),
                  _c("span", { staticClass: "trial-container-info-new" }, [
                    _vm._v(_vm._s(_vm.newPlanType)),
                  ]),
                ]),
                _c("div", [
                  _vm._v("\n            Subscription Plan Id: "),
                  _c("span", { staticClass: "trial-container-info-new" }, [
                    _vm._v(_vm._s(_vm.newSubscriptonPlanId)),
                  ]),
                ]),
                _c("div", [
                  _vm._v("\n            Expiry Date: "),
                  _c("span", { staticClass: "trial-container-info-new" }, [
                    _vm._v(_vm._s(_vm.newExpiryDate)),
                  ]),
                ]),
                _c("div", [
                  _vm._v("\n            Claims: "),
                  _c("span", { staticClass: "trial-container-info-new" }, [
                    _vm._v(_vm._s(_vm.newTrialClaims)),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "vs-button",
              {
                staticClass: "mr-2 mt-2",
                attrs: { color: "#e8546f", type: "filled" },
                on: { click: _vm.onExtendTrialPeriod },
              },
              [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
            ),
            _c(
              "vs-button",
              {
                attrs: { type: "border", color: "#e8546f" },
                on: {
                  click: function ($event) {
                    _vm.showExtendTrialPeriod = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("vue.cancel")))]
            ),
          ],
          1
        ),
        _c(
          "vs-popup",
          {
            attrs: {
              title: "Extend Trial Period",
              active: _vm.showExtendVegaTrialPeriod,
              width: "800px",
            },
            on: {
              "update:active": function ($event) {
                _vm.showExtendVegaTrialPeriod = $event
              },
            },
          },
          [
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("vue.confirmQuestion")) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "trial-container" }, [
              _c("div", { staticClass: "trial-container-info" }, [
                _c("div", { staticClass: "trial-container-info-header" }, [
                  _vm._v("Current"),
                ]),
                _c("div", [
                  _vm._v("\n            Expiry Date: "),
                  _c("span", { staticClass: "trial-container-info-current" }, [
                    _vm._v(_vm._s(_vm.currentVegaExpiryDate)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "trial-container-info" }, [
                _c("div", { staticClass: "trial-container-info-header" }, [
                  _vm._v("New"),
                ]),
                _c("div", [
                  _vm._v("\n            Expiry Date: "),
                  _c("span", { staticClass: "trial-container-info-new" }, [
                    _vm._v(_vm._s(_vm.newVegaExpiryDate)),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "vs-button",
              {
                staticClass: "mr-2 mt-2",
                attrs: { color: "#e8546f", type: "filled" },
                on: { click: _vm.onExtendVegaTrialPeriod },
              },
              [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
            ),
            _c(
              "vs-button",
              {
                attrs: { type: "border", color: "#e8546f" },
                on: {
                  click: function ($event) {
                    _vm.showExtendVegaTrialPeriod = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("vue.cancel")))]
            ),
          ],
          1
        ),
        _c("vs-card", { staticClass: "cardx", attrs: { fixedHeight: "" } }, [
          _vm.company
            ? _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.company.name) +
                      " (" +
                      _vm._s(_vm.companyId) +
                      ")"
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-base" },
              [
                _vm.company
                  ? _c(
                      "vx-card",
                      { staticClass: "overflow-hidden dark-theme" },
                      [
                        _c(
                          "ul",
                          { staticClass: "centerx" },
                          _vm._l(
                            _vm.selectedCustomClaims,
                            function (claim, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  _c(
                                    "vs-checkbox",
                                    {
                                      attrs: { icon: "flash_on" },
                                      model: {
                                        value: claim.selected,
                                        callback: function ($$v) {
                                          _vm.$set(claim, "selected", $$v)
                                        },
                                        expression: "claim.selected",
                                      },
                                    },
                                    [_vm._v(_vm._s(claim.name))]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-base" },
              [
                _vm.company
                  ? _c(
                      "vx-card",
                      { staticClass: "overflow-hidden dark-theme" },
                      [
                        _c("h4", [_vm._v("Select total users")]),
                        _c("vs-input", {
                          staticClass: "w-75",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.maxUsers,
                            callback: function ($$v) {
                              _vm.maxUsers = $$v
                            },
                            expression: "maxUsers",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-base" },
              [
                _vm.company
                  ? _c(
                      "vx-card",
                      { staticClass: "overflow-hidden dark-theme" },
                      [
                        _c("h4", [_vm._v("Extend Trial Period By days")]),
                        _c("div", { staticClass: "flex justify-start mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c("vs-input", {
                                staticClass: "w-75",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.extendTrialPeriodByDays,
                                  callback: function ($$v) {
                                    _vm.extendTrialPeriodByDays = $$v
                                  },
                                  expression: "extendTrialPeriodByDays",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "vs-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showExtendTrialPeriod = true
                                    },
                                  },
                                },
                                [_vm._v("Extend Trial Period")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-base" },
              [
                _vm.company
                  ? _c(
                      "vx-card",
                      { staticClass: "overflow-hidden dark-theme" },
                      [
                        _c("h4", [_vm._v("Extend Vega Trial Period By days")]),
                        _c("div", { staticClass: "flex justify-start mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c("vs-input", {
                                staticClass: "w-75",
                                attrs: {
                                  disabled: !_vm.isVegaOnTrial,
                                  type: "number",
                                },
                                model: {
                                  value: _vm.extendVegaTrialPeriodByDays,
                                  callback: function ($$v) {
                                    _vm.extendVegaTrialPeriodByDays = $$v
                                  },
                                  expression: "extendVegaTrialPeriodByDays",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { disabled: !_vm.isVegaOnTrial },
                                  on: {
                                    click: function ($event) {
                                      _vm.showExtendVegaTrialPeriod = true
                                    },
                                  },
                                },
                                [_vm._v("Extend Trial Period")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-base" },
              [
                _vm.company
                  ? _c(
                      "vx-card",
                      { staticClass: "overflow-hidden dark-theme" },
                      [
                        _c("h3", [_vm._v("Is Vega Account")]),
                        _c("p", [
                          _vm._v("Check/Uncheck to save it automatically"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex justify-start mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                attrs: { icon: "flash_on" },
                                on: { change: _vm.onUpdateIsVegaAccount },
                                model: {
                                  value: _vm.isVegaAccount,
                                  callback: function ($$v) {
                                    _vm.isVegaAccount = $$v
                                  },
                                  expression: "isVegaAccount",
                                },
                              },
                              [
                                _vm.isVegaAccount
                                  ? _c(
                                      "vs-chip",
                                      {
                                        attrs: {
                                          size: "small",
                                          color: "primary",
                                        },
                                      },
                                      [
                                        _c("vs-avatar", {
                                          attrs: {
                                            icon: "icon-check",
                                            "icon-pack": "feather",
                                          },
                                        }),
                                        _vm._v("Yes "),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "vs-chip",
                                      {
                                        attrs: {
                                          size: "small",
                                          color: "danger",
                                        },
                                      },
                                      [
                                        _c("vs-avatar", {
                                          attrs: {
                                            icon: "icon-x",
                                            "icon-pack": "feather",
                                          },
                                        }),
                                        _vm._v("No "),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-base" },
              [
                _vm.company
                  ? _c(
                      "vx-card",
                      { staticClass: "overflow-hidden dark-theme" },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c("div", { staticClass: "mr-10" }, [
                            _c("h3", [_vm._v("Managed Service")]),
                            _c("p", [
                              _vm._v("Check/Uncheck to save it automatically"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex justify-start mt-2" },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    attrs: { icon: "flash_on" },
                                    on: { change: _vm.onUpdateCallCenter },
                                    model: {
                                      value: _vm.isCallCenter,
                                      callback: function ($$v) {
                                        _vm.isCallCenter = $$v
                                      },
                                      expression: "isCallCenter",
                                    },
                                  },
                                  [
                                    _vm.isCallCenter
                                      ? _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              size: "small",
                                              color: "primary",
                                            },
                                          },
                                          [
                                            _c("vs-avatar", {
                                              attrs: {
                                                icon: "icon-check",
                                                "icon-pack": "feather",
                                              },
                                            }),
                                            _vm._v("Yes "),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              size: "small",
                                              color: "danger",
                                            },
                                          },
                                          [
                                            _c("vs-avatar", {
                                              attrs: {
                                                icon: "icon-x",
                                                "icon-pack": "feather",
                                              },
                                            }),
                                            _vm._v("No "),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", [
                            _c("h3", [_vm._v("Managed Service Customer")]),
                            _c("p", [
                              _vm._v("Check/Uncheck to save it automatically"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex justify-start mt-2" },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    attrs: { icon: "flash_on" },
                                    on: {
                                      change: _vm.onUpdateCallCenterCustomer,
                                    },
                                    model: {
                                      value: _vm.isCallCenterCustomer,
                                      callback: function ($$v) {
                                        _vm.isCallCenterCustomer = $$v
                                      },
                                      expression: "isCallCenterCustomer",
                                    },
                                  },
                                  [
                                    _vm.isCallCenterCustomer
                                      ? _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              size: "small",
                                              color: "primary",
                                            },
                                          },
                                          [
                                            _c("vs-avatar", {
                                              attrs: {
                                                icon: "icon-check",
                                                "icon-pack": "feather",
                                              },
                                            }),
                                            _vm._v("Yes "),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              size: "small",
                                              color: "danger",
                                            },
                                          },
                                          [
                                            _c("vs-avatar", {
                                              attrs: {
                                                icon: "icon-x",
                                                "icon-pack": "feather",
                                              },
                                            }),
                                            _vm._v("No "),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "vs-row",
                { attrs: { "vs-justify": "flex-end" } },
                [
                  _c("vs-button", {
                    staticClass: "mr-2",
                    attrs: { type: "gradient", color: "danger", icon: "save" },
                    on: { click: _vm.saveCustomClaims },
                  }),
                  _c("vs-button", {
                    staticClass: "mr-2",
                    attrs: { color: "primary", icon: "close" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-custom-claims")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }