<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <company-detail v-show="showCompanyDetail" :visitor="sidebarData"></company-detail>
    <coupon v-show="showCoupon"></coupon>
    <custom-claims v-if="showCustomClaims" :companyId="selectedCompany" @close-custom-claims="onCloseCustomClaims"></custom-claims>
    <invoice v-if="showInvoice" :companyId="selectedCompany" @close-invoice="onCloseInvoice"></invoice>
    <vs-popup :title="`${$t('vue.deleteSelectedAccounts')} (Total: ${selected.length})`" :active.sync="deleteSelectedAccounts">
      <p class="mb-2">
        {{ $t('vue.confirmQuestion') }}
      </p>
      <vs-button @click="onDeleteSelectedAccounts" color="#e8546f" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click="onCancelAccountDeletion" type="border" color="#e8546f">{{ $t('vue.cancel') }}</vs-button>
    </vs-popup>

    <create-free-trial-account :showPopup="showNewAccountPopup"></create-free-trial-account>
    <div class="vx-row" v-if="!showNewAccountPopup">
      <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4">
        <vs-button @click="showNewAccountPopup = true">{{ $t('vue.createNewAccount') }}</vs-button>
      </div>
    </div>

    <vs-table
      :key="tableKey"
      v-show="!showCompanyDetail && !showCoupon && !showCustomClaims && !showInvoice && !showNewAccountPopup"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="companies"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center"></div>
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            style="border-radius: 5px"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{ companies.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : companies.length }} of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 5">
              <span>5</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <vs-th>
          <vx-tooltip :text="$t('vue.delete')" class="inline-flex" position="top" v-if="selected.length > 0 && selected.length <= itemsPerPage">
            <vs-button :color="'#F05541'" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click="deleteSelectedAccounts = true"></vs-button>
          </vx-tooltip>
        </vs-th>
        <vs-th sort-key="name">
          {{ $t('vue.date') }}
        </vs-th>
        <vs-th sort-key="name">
          {{ $t('vue.name') }}
        </vs-th>
        <vs-th sort-key="utm_source">
          {{ $t('vue.source') }}
        </vs-th>
        <vs-th sort-key="phone">
          {{ $t('vue.phone') }}
        </vs-th>
        <vs-th sort-key="url">
          {{ $t('vue.url') }}
        </vs-th>
        <vs-th>
          {{ $t('vue.address') }}
        </vs-th>
        <vs-th>{{ $t('vue.action') }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><check-circle-icon color="#28c76f" :width="14" :height="14" v-if="hasCompletedProcess(tr)" /></vs-td>

            <vs-td :data="tr.date">
              {{ moment.unix(tr.created.seconds).locale(locale).format('LLL') }}
            </vs-td>

            <vs-td :data="tr.name">
              <div class="flex flex-column items-start">
                <div v-if="tr.isShopifyAccount">
                  <div><shopify-icon :width="60" :height="17"></shopify-icon></div>
                </div>
                <div v-else-if="tr.isAppSumoAccount">
                  <div><AppSumoLogoDark :width="60" :height="10" /></div>
                </div>
                <div>{{ tr.name }} - {{ tr.id }}</div>
              </div>
            </vs-td>

            <vs-td :data="tr.utm_source">
              <div class="custom-tooltip">
                <social-icons :key="tr.id" :name="getUtmSource(tr.utm_source)"></social-icons>
                <div v-if="tr.utm_campaign || tr.utm_medium || tr.utm_term" class="tooltiptext">
                  <div v-if="tr.utm_campaign && tr.utm_campaign.length > 0">utm_campaign : {{ tr.utm_campaign }}</div>
                  <div v-if="tr.utm_medium && tr.utm_medium.length > 0">utm_medium : {{ tr.utm_medium }}</div>
                  <div v-if="tr.utm_term && tr.utm_term.length > 0">utm_term : {{ tr.utm_term }}</div>
                </div>
              </div>
            </vs-td>

            <vs-td :data="tr.phone">
              {{ tr.phone }}
            </vs-td>

            <vs-td :data="tr.url">
              {{ tr.url }}
            </vs-td>

            <vs-td>
              <p v-if="tr.street">
                <strong>{{ $t('vue.street') }} : </strong>{{ tr.street }}
              </p>
              <p v-if="tr.city">
                <strong>{{ $t('vue.city') }} : </strong>{{ tr.city }}
              </p>
              <p v-if="tr.zipcode">
                <strong>{{ $t('vue.zipcode') }} : </strong>{{ tr.zipcode }}
              </p>
              <p v-if="tr.country">
                <strong>{{ $t('vue.country') }} : </strong>{{ getCountry(tr.country) }}
              </p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <div class="ml-2" v-if="!tr.currentInvoiceId && !tr.mollieSubscriptionId && !tr.subscriptionId">
                  <vx-tooltip :text="$t('vue.generateInvoice')" position="top">
                    <vs-button
                      :disabled="selected && selected.length > 0"
                      color="success"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-file-text"
                      @click="onShowGenerateNewInvoice(tr)"
                    ></vs-button>
                  </vx-tooltip>
                </div>
                <div class="ml-2" v-if="tr.currentInvoiceId && false">
                  <vx-tooltip :text="$t('vue.generateInvoice')" position="top">
                    <vs-button
                      :disabled="selected && selected.length > 0"
                      color="warning"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-file-text"
                      @click="onShowEditInvoice(tr)"
                    ></vs-button>
                  </vx-tooltip>
                </div>

                <div class="ml-2">
                  <vx-tooltip :text="$t('vue.viewAffiliate')" position="top">
                    <vs-button color="primary" type="border" size="medium" icon-pack="feather" icon="icon-info" @click.stop="viewData(tr)"></vs-button>
                  </vx-tooltip>
                </div>
                <div class="ml-2">
                  <vx-tooltip :text="$t('vue.updateCustomClaims')" position="top">
                    <vs-button color="primary" type="border" size="medium" icon-pack="feather" icon="icon-zap" @click.stop="viewCustomClaims(tr)"></vs-button>
                  </vx-tooltip>
                </div>
                <div class="ml-2" v-if="false">
                  <vs-button
                    :disabled="!tr.affiliateCompany || !tr.affiliatePartner"
                    color="primary"
                    type="border"
                    size="medium"
                    icon-pack="feather"
                    icon="icon-gift"
                    @click.stop="viewCoupon(tr)"
                  ></vs-button>
                </div>
                <div class="ml-2">
                  <vx-tooltip :text="$t('vue.impersonate')" position="top">
                    <vs-button
                      v-if="activeUserInfo.uid !== tr.createdBy"
                      color="primary"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-user"
                      @click.stop="impersonate(tr.createdBy)"
                    ></vs-button>
                  </vx-tooltip>
                </div>
                <div class="ml-2">
                  <vx-tooltip :text="$t('vue.deleteAccount')" position="top">
                    <vs-button
                      :disabled="selected && selected.length > 0"
                      color="danger"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click="onDeleteAccount()"
                    ></vs-button>
                  </vx-tooltip>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

import moment from 'moment'
import CompanyDetail from './CompanyDetail'
import Coupon from './Coupon'
import CustomClaims from './CustomClaims.vue'
import Invoice from './Invoice.vue'
import CreateFreeTrialAccount from './CreateFreeTrialAccount'

import SocialIcons from '@/components/icons/SocialIcons.vue'
import ShopifyIcon from '@/components/icons/ShopifyIcon.vue'
import AppSumoLogoDark from '@/components/icons/appsumo/AppSumoLogoDark.vue'
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue'

export default {
  components: {
    CompanyDetail,
    Coupon,
    SocialIcons,
    CheckCircleIcon,
    CustomClaims,
    Invoice,
    ShopifyIcon,
    AppSumoLogoDark,
    CreateFreeTrialAccount
  },
  data() {
    return {
      moment,
      selected: [],
      deleteSelectedAccounts: false,
      companies: [],
      isMounted: false,
      itemsPerPage: 5,
      sidebarData: {},
      showCompanyDetail: false,
      showCoupon: false,
      showCustomClaims: false,
      selectedCompany: null,
      showInvoice: false,
      tableKey: Math.random().toString(36).substring(2, 15),
      showNewAccountPopup: false
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    locale() {
      return this.$i18n.locale
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.companies.length
    }
  },
  methods: {
    async onGetAllCompanies() {
      const allCompanies = []
      this.$vs.loading()
      const companies = await this.$db.collection('company').orderBy('created', 'desc').get()
      companies.forEach((doc) => {
        const company = doc.data()
        const _company = {
          id: doc.id,
          name: company.name,
          created: company.created,
          utm_source: company.utm_source || '',
          utm_campaign: company.utm_campaign || '',
          utm_medium: company.utm_medium || '',
          utm_term: company.utm_term || '',
          phone: company.phone,
          url: company.url,
          street: company.street,
          createdByName: company.createdByName,
          city: company.city,
          zipcode: company.zipcode,
          country: company.country,
          createdBy: company.createdBy,
          currentInvoiceId: company.currentInvoiceId,
          mollieSubscriptionId: company.mollieSubscriptionId,
          subscriptionId: company.subscriptionId,
          subscriptionPlanId: company.subscriptionPlanId,
          isShopifyAccount: company.isShopifyAccount,
          isAppSumoAccount: company.isAppSumoAccount,
          paymentStatus: company.paymentStatus
        }

        allCompanies.push(_company)
      })
      if (allCompanies.length > 0) {
        this.companies = allCompanies
      }
      this.$vs.loading.close()
    },
    hasCompletedProcess(company) {
      if (company.subscriptionId || company.mollieSubscriptionId || company.currentInvoiceId) {
        return true
      }
      if (company.paymentStatus) {
        return true
      }
      return false
    },
    onCloseCustomClaims() {
      this.showCustomClaims = false
      this.selectedCompany = null
    },
    async onCloseInvoice(event) {
      if (event && event.type === 'created' && this.selectedCompany) {
        const doc = await this.$db.collection('company').doc(this.selectedCompany).get()
        const company = doc.data()
        const _company = {
          id: doc.id,
          name: company.name,
          created: company.created,
          utm_source: company.utm_source || '',
          utm_campaign: company.utm_campaign || '',
          utm_medium: company.utm_medium || '',
          utm_term: company.utm_term || '',
          phone: company.phone,
          url: company.url,
          street: company.street,
          city: company.city,
          createdByName: company.createdByName,
          zipcode: company.zipcode,
          country: company.country,
          createdBy: company.createdBy,
          currentInvoiceId: event.currentInvoiceId,
          mollieSubscriptionId: company.mollieSubscriptionId,
          subscriptionId: company.subscriptionId,
          subscriptionPlanId: company.subscriptionPlanId
        }
        const index = this.companies.findIndex((x) => x.id === this.selectedCompany)
        this.companies[index] = _company
      }

      this.showInvoice = false
      this.selectedCompany = null
      this.selected = []
      this.tableKey = Math.random().toString(36).substring(2, 15)
    },
    onDeleteAccount() {
      this.deleteSelectedAccounts = true
    },
    async onDeleteSelectedAccounts() {
      if (this.selected.length > this.itemsPerPage) {
        return
      }
      if (this.selected.find((x) => x.name === 'PATHADVICE International GmbH')) {
        return
      }
      this.deleteSelectedAccounts = false
      this.$vs.loading()

      await Promise.all(
        this.selected.map(async (account) => {
          await this.$db.collection('company').doc(account.id).delete()
          this.companies = this.companies.filter((x) => x.id !== account.id)
        })
      )

      this.selected = []

      setTimeout(() => {
        this.$vs.loading.close()
      }, 300)
    },
    onCancelAccountDeletion() {
      this.deleteSelectedAccounts = false
      this.selected = []
    },
    getCountry(country) {
      if (country && country.countryName) {
        return country.countryName
      }
      return country
    },
    getUtmSource(value) {
      let source = value || 'direct'
      source.toLowerCase()
      source = source === 'google' || source === 'facebook' || source === 'linkedin' || source === 'bing' || source === 'mail' ? source : 'direct'
      return source
    },
    async impersonate(uid) {
      const vm = this
      vm.$vs.loading({ type: 'point' })
      try {
        const getUserCustomToken = vm.$functions.httpsCallable('getUserCustomToken')
        const name = vm.activeUserInfo.firstname ? `${vm.activeUserInfo.firstname} ${vm.activeUserInfo.lastname}` : vm.activeUserInfo.displayName
        const result = await getUserCustomToken({ uid, impersonate: true, loggedInUser: { uid: vm.activeUserInfo.uid, name } })
        if (result.data && result.data.status === true) {
          const token = result.data.token
          await firebase.auth().signInWithCustomToken(token)
          location.href = '/'
        } else {
          const message = vm.$i18n.t(`error.${result.data.messageCode}`)
          vm.$vs.notify({
            time: 4000,
            title: vm.$i18n.t('vue.error'),
            text: message,
            color: 'danger',
            iconPack: 'feather',
            position: 'bottom-right',
            icon: 'icon-alert-circle'
          })
          vm.$vs.loading.close()
        }
      } catch (error) {
        const message = error.message
        vm.$vs.notify({
          time: 4000,
          title: vm.$i18n.t('vue.error'),
          text: message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
        vm.$vs.loading.close()
      }
    },
    viewData(data) {
      const vm = this
      vm.showCompanyDetail = true
      vm.$serverBus.$emit('get-company-detail', data)
    },
    viewCustomClaims(data) {
      this.showCustomClaims = true
      this.selectedCompany = data.id
    },
    viewCoupon(data) {
      const vm = this
      vm.showCoupon = true
      vm.$serverBus.$emit('show-coupon-code', data)
    },
    onShowGenerateNewInvoice(data) {
      this.selectedCompany = data.id
      this.showInvoice = true
    },
    onShowEditInvoice(data) {
      this.selectedCompany = data.id
      this.showInvoice = true
    }
  },
  created() {
    this.onGetAllCompanies()
  },
  mounted() {
    this.isMounted = true
    this.$serverBus.$on('close-company-detail', () => {
      this.showCompanyDetail = false
    })
    this.$serverBus.$on('close-coupon-code', () => {
      this.showCoupon = false
    })
    this.$serverBus.$on('close-create-account', () => {
      this.showNewAccountPopup = false
    })
    this.$serverBus.$on('created-account-successfully', async () => {
      this.showNewAccountPopup = false
      await this.onGetAllCompanies()
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('close-company-detail')
    this.$serverBus.$off('close-coupon-code')
    this.$serverBus.$off('close-create-account')
    this.$serverBus.$off('created-account-successfully')
  },
}
</script>

<style lang="scss">
#data-list-thumb-view {
  .custom-tooltip .tooltiptext {
    display: block;
    width: max-content;
    background-color: rgba(var(--vs-secondary), 1) !important;
    color: #fff;
    text-align: justify;
    border-radius: 6px;
    padding: 10px;
    font-size: 0.8rem;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 400;
    height: fit-content;
    white-space: nowrap;

    /* Position the tooltip */
    position: relative;
    z-index: 60000;
  }

  .custom-tooltip {
    position: relative;
    display: inline-block;
  }

  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
          box-shadow: none !important;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
