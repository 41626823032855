var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      attrs: { id: "data-list-thumb-view" },
    },
    [
      _c("company-detail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCompanyDetail,
            expression: "showCompanyDetail",
          },
        ],
        attrs: { visitor: _vm.sidebarData },
      }),
      _c("coupon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCoupon,
            expression: "showCoupon",
          },
        ],
      }),
      _vm.showCustomClaims
        ? _c("custom-claims", {
            attrs: { companyId: _vm.selectedCompany },
            on: { "close-custom-claims": _vm.onCloseCustomClaims },
          })
        : _vm._e(),
      _vm.showInvoice
        ? _c("invoice", {
            attrs: { companyId: _vm.selectedCompany },
            on: { "close-invoice": _vm.onCloseInvoice },
          })
        : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t("vue.deleteSelectedAccounts") +
              " (Total: " +
              _vm.selected.length +
              ")",
            active: _vm.deleteSelectedAccounts,
          },
          on: {
            "update:active": function ($event) {
              _vm.deleteSelectedAccounts = $event
            },
          },
        },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("vue.confirmQuestion")) + "\n    "
            ),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: { click: _vm.onDeleteSelectedAccounts },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: { click: _vm.onCancelAccountDeletion },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancel")))]
          ),
        ],
        1
      ),
      _c("create-free-trial-account", {
        attrs: { showPopup: _vm.showNewAccountPopup },
      }),
      !_vm.showNewAccountPopup
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              {
                staticClass: "vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4",
              },
              [
                _c(
                  "vs-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showNewAccountPopup = true
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("vue.createNewAccount")))]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "vs-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.showCompanyDetail &&
                !_vm.showCoupon &&
                !_vm.showCustomClaims &&
                !_vm.showInvoice &&
                !_vm.showNewAccountPopup,
              expression:
                "!showCompanyDetail && !showCoupon && !showCustomClaims && !showInvoice && !showNewAccountPopup",
            },
          ],
          key: _vm.tableKey,
          ref: "table",
          attrs: {
            multiple: "",
            pagination: "",
            "max-items": _vm.itemsPerPage,
            search: "",
            data: _vm.companies,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c(
                            "vs-td",
                            [
                              _vm.hasCompletedProcess(tr)
                                ? _c("check-circle-icon", {
                                    attrs: {
                                      color: "#28c76f",
                                      width: 14,
                                      height: 14,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("vs-td", { attrs: { data: tr.date } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.moment
                                    .unix(tr.created.seconds)
                                    .locale(_vm.locale)
                                    .format("LLL")
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { attrs: { data: tr.name } }, [
                            _c(
                              "div",
                              { staticClass: "flex flex-column items-start" },
                              [
                                tr.isShopifyAccount
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        [
                                          _c("shopify-icon", {
                                            attrs: { width: 60, height: 17 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : tr.isAppSumoAccount
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        [
                                          _c("AppSumoLogoDark", {
                                            attrs: { width: 60, height: 10 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(tr.name) + " - " + _vm._s(tr.id)
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("vs-td", { attrs: { data: tr.utm_source } }, [
                            _c(
                              "div",
                              { staticClass: "custom-tooltip" },
                              [
                                _c("social-icons", {
                                  key: tr.id,
                                  attrs: {
                                    name: _vm.getUtmSource(tr.utm_source),
                                  },
                                }),
                                tr.utm_campaign || tr.utm_medium || tr.utm_term
                                  ? _c("div", { staticClass: "tooltiptext" }, [
                                      tr.utm_campaign &&
                                      tr.utm_campaign.length > 0
                                        ? _c("div", [
                                            _vm._v(
                                              "utm_campaign : " +
                                                _vm._s(tr.utm_campaign)
                                            ),
                                          ])
                                        : _vm._e(),
                                      tr.utm_medium && tr.utm_medium.length > 0
                                        ? _c("div", [
                                            _vm._v(
                                              "utm_medium : " +
                                                _vm._s(tr.utm_medium)
                                            ),
                                          ])
                                        : _vm._e(),
                                      tr.utm_term && tr.utm_term.length > 0
                                        ? _c("div", [
                                            _vm._v(
                                              "utm_term : " +
                                                _vm._s(tr.utm_term)
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("vs-td", { attrs: { data: tr.phone } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(tr.phone) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { attrs: { data: tr.url } }, [
                            _vm._v(
                              "\n            " + _vm._s(tr.url) + "\n          "
                            ),
                          ]),
                          _c("vs-td", [
                            tr.street
                              ? _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("vue.street")) + " : "
                                    ),
                                  ]),
                                  _vm._v(_vm._s(tr.street) + "\n            "),
                                ])
                              : _vm._e(),
                            tr.city
                              ? _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("vue.city")) + " : "),
                                  ]),
                                  _vm._v(_vm._s(tr.city) + "\n            "),
                                ])
                              : _vm._e(),
                            tr.zipcode
                              ? _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("vue.zipcode")) + " : "
                                    ),
                                  ]),
                                  _vm._v(_vm._s(tr.zipcode) + "\n            "),
                                ])
                              : _vm._e(),
                            tr.country
                              ? _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("vue.country")) + " : "
                                    ),
                                  ]),
                                  _vm._v(
                                    _vm._s(_vm.getCountry(tr.country)) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("vs-td", { staticClass: "whitespace-no-wrap" }, [
                            _c("div", { staticClass: "flex" }, [
                              !tr.currentInvoiceId &&
                              !tr.mollieSubscriptionId &&
                              !tr.subscriptionId
                                ? _c(
                                    "div",
                                    { staticClass: "ml-2" },
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            text: _vm.$t("vue.generateInvoice"),
                                            position: "top",
                                          },
                                        },
                                        [
                                          _c("vs-button", {
                                            attrs: {
                                              disabled:
                                                _vm.selected &&
                                                _vm.selected.length > 0,
                                              color: "success",
                                              size: "medium",
                                              "icon-pack": "feather",
                                              icon: "icon-file-text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onShowGenerateNewInvoice(
                                                  tr
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              tr.currentInvoiceId && false
                                ? _c(
                                    "div",
                                    { staticClass: "ml-2" },
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            text: _vm.$t("vue.generateInvoice"),
                                            position: "top",
                                          },
                                        },
                                        [
                                          _c("vs-button", {
                                            attrs: {
                                              disabled:
                                                _vm.selected &&
                                                _vm.selected.length > 0,
                                              color: "warning",
                                              size: "medium",
                                              "icon-pack": "feather",
                                              icon: "icon-file-text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onShowEditInvoice(tr)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("vue.viewAffiliate"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _c("vs-button", {
                                        attrs: {
                                          color: "primary",
                                          type: "border",
                                          size: "medium",
                                          "icon-pack": "feather",
                                          icon: "icon-info",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.viewData(tr)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("vue.updateCustomClaims"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _c("vs-button", {
                                        attrs: {
                                          color: "primary",
                                          type: "border",
                                          size: "medium",
                                          "icon-pack": "feather",
                                          icon: "icon-zap",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.viewCustomClaims(tr)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "div",
                                    { staticClass: "ml-2" },
                                    [
                                      _c("vs-button", {
                                        attrs: {
                                          disabled:
                                            !tr.affiliateCompany ||
                                            !tr.affiliatePartner,
                                          color: "primary",
                                          type: "border",
                                          size: "medium",
                                          "icon-pack": "feather",
                                          icon: "icon-gift",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.viewCoupon(tr)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("vue.impersonate"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _vm.activeUserInfo.uid !== tr.createdBy
                                        ? _c("vs-button", {
                                            attrs: {
                                              color: "primary",
                                              type: "border",
                                              size: "medium",
                                              "icon-pack": "feather",
                                              icon: "icon-user",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.impersonate(
                                                  tr.createdBy
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("vue.deleteAccount"),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _c("vs-button", {
                                        attrs: {
                                          disabled:
                                            _vm.selected &&
                                            _vm.selected.length > 0,
                                          color: "danger",
                                          type: "border",
                                          size: "medium",
                                          "icon-pack": "feather",
                                          icon: "icon-trash",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDeleteAccount()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap-reverse items-center flex-grow justify-between",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", { staticClass: "flex flex-wrap-reverse items-center" }),
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer mb-4 mr-4",
                  attrs: { "vs-trigger-click": "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                      staticStyle: { "border-radius": "5px" },
                    },
                    [
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentPage * _vm.itemsPerPage -
                              (_vm.itemsPerPage - 1)
                          ) +
                            " -\n            " +
                            _vm._s(
                              _vm.companies.length -
                                _vm.currentPage * _vm.itemsPerPage >
                                0
                                ? _vm.currentPage * _vm.itemsPerPage
                                : _vm.companies.length
                            ) +
                            " of " +
                            _vm._s(_vm.queriedItems)
                        ),
                      ]),
                      _c("feather-icon", {
                        attrs: {
                          icon: "ChevronDownIcon",
                          svgClasses: "h-4 w-4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-dropdown-menu",
                    [
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = 5
                            },
                          },
                        },
                        [_c("span", [_vm._v("5")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = 10
                            },
                          },
                        },
                        [_c("span", [_vm._v("10")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = 15
                            },
                          },
                        },
                        [_c("span", [_vm._v("15")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = 20
                            },
                          },
                        },
                        [_c("span", [_vm._v("20")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                [
                  _vm.selected.length > 0 &&
                  _vm.selected.length <= _vm.itemsPerPage
                    ? _c(
                        "vx-tooltip",
                        {
                          staticClass: "inline-flex",
                          attrs: {
                            text: _vm.$t("vue.delete"),
                            position: "top",
                          },
                        },
                        [
                          _c("vs-button", {
                            attrs: {
                              color: "#F05541",
                              type: "border",
                              size: "medium",
                              "icon-pack": "feather",
                              icon: "icon-trash",
                            },
                            on: {
                              click: function ($event) {
                                _vm.deleteSelectedAccounts = true
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("vs-th", { attrs: { "sort-key": "name" } }, [
                _vm._v("\n        " + _vm._s(_vm.$t("vue.date")) + "\n      "),
              ]),
              _c("vs-th", { attrs: { "sort-key": "name" } }, [
                _vm._v("\n        " + _vm._s(_vm.$t("vue.name")) + "\n      "),
              ]),
              _c("vs-th", { attrs: { "sort-key": "utm_source" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("vue.source")) + "\n      "
                ),
              ]),
              _c("vs-th", { attrs: { "sort-key": "phone" } }, [
                _vm._v("\n        " + _vm._s(_vm.$t("vue.phone")) + "\n      "),
              ]),
              _c("vs-th", { attrs: { "sort-key": "url" } }, [
                _vm._v("\n        " + _vm._s(_vm.$t("vue.url")) + "\n      "),
              ]),
              _c("vs-th", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("vue.address")) + "\n      "
                ),
              ]),
              _c("vs-th", [_vm._v(_vm._s(_vm.$t("vue.action")))]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }