var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-bottom-2x flex" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPopup,
            expression: "showPopup",
          },
        ],
        staticClass: "vx-col w-full lg:w-1/1 sm:w-1/1 mb-base",
      },
      [
        _c(
          "div",
          { staticClass: "flex justify-align-center" },
          [
            _c("vx-card", { staticClass: "add-coupon" }, [
              _c("div", { attrs: { id: "coupon-container" } }, [
                _c(
                  "div",
                  {
                    staticClass: "pt-2 pb-2 pr-2 pl-2",
                    attrs: { align: "right" },
                  },
                  [
                    _c("vs-button", {
                      attrs: {
                        color: "primary",
                        type: "filled",
                        "icon-pack": "feather",
                        icon: "icon-x",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "custom-card__header",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("vue.completeYourRegistration"))),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "vx-row mt-6 mb-6" }, [
                  _c("div", { staticClass: "vx-col" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("inputs.firstName")))]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min:3",
                            expression: "'required|min:3'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          name: _vm.$t("inputs.firstName"),
                          "data-vv-as": _vm.$t("inputs.firstName"),
                          "validate-on": "blur",
                        },
                        model: {
                          value: _vm.firstname,
                          callback: function ($$v) {
                            _vm.firstname = $$v
                          },
                          expression: "firstname",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formSubmitted &&
                  _vm.errors.first(_vm.$t("inputs.firstName"))
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full error-message" },
                        [
                          _vm._v(
                            "\n              * " +
                              _vm._s(
                                _vm.errors.first(_vm.$t("inputs.firstName"))
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("span", { staticClass: "w-full" }, [
                      _vm._v(_vm._s(_vm.$t("inputs.lastName"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min:3",
                            expression: "'required|min:3'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          name: _vm.$t("inputs.lastName"),
                          "data-vv-as": _vm.$t("inputs.lastName"),
                          "validate-on": "blur",
                        },
                        model: {
                          value: _vm.lastname,
                          callback: function ($$v) {
                            _vm.lastname = $$v
                          },
                          expression: "lastname",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formSubmitted &&
                  _vm.errors.first(_vm.$t("inputs.lastName"))
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full error-message" },
                        [
                          _vm._v(
                            "\n              * " +
                              _vm._s(
                                _vm.errors.first(_vm.$t("inputs.lastName"))
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("span", { staticClass: "w-full" }, [
                      _vm._v(_vm._s(_vm.$t("inputs.email"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required: true,
                              email: true,
                              isEmailAlreadyTaken: true,
                            },
                            expression:
                              "{\n                  required: true,\n                  email: true,\n                  isEmailAlreadyTaken: true\n                }",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          name: _vm.$t("inputs.email"),
                          "data-vv-as": _vm.$t("inputs.email"),
                          "validate-on": "blur",
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formSubmitted && _vm.errors.first(_vm.$t("inputs.email"))
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full error-message" },
                        [
                          _vm._v(
                            "* " +
                              _vm._s(_vm.errors.first(_vm.$t("inputs.email")))
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("span", { staticClass: "w-full" }, [
                      _vm._v(_vm._s(_vm.$t("inputs.companyName"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min:3",
                            expression: "'required|min:3'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          name: _vm.$t("inputs.companyName"),
                          "data-vv-as": _vm.$t("inputs.companyName"),
                          "validate-on": "blur",
                        },
                        model: {
                          value: _vm.accountName,
                          callback: function ($$v) {
                            _vm.accountName = $$v
                          },
                          expression: "accountName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formSubmitted &&
                  _vm.errors.first(_vm.$t("inputs.companyName"))
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full error-message" },
                        [
                          _vm._v(
                            "\n              * " +
                              _vm._s(
                                _vm.errors.first(_vm.$t("inputs.companyName"))
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("span", { staticClass: "w-full" }, [
                      _vm._v(_vm._s(_vm.$t("vue.expiryDate"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("datepicker", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          name: _vm.$t("vue.expiryDate"),
                          format: "MM/dd/yyyy",
                          disabledDates: { to: new Date(Date.now() - 8640000) },
                          inline: true,
                        },
                        model: {
                          value: _vm.expiryDate,
                          callback: function ($$v) {
                            _vm.expiryDate = $$v
                          },
                          expression: "expiryDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formSubmitted &&
                  _vm.errors.first(_vm.$t("vue.expiryDate"))
                    ? _c(
                        "div",
                        { staticClass: "vx-col w-full error-message" },
                        [
                          _vm._v(
                            "\n              * " +
                              _vm._s(
                                _vm.errors.first(_vm.$t("vue.expiryDate"))
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col flex items-center justify-end w-full",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            disabled:
                              _vm.isCreatingAccount ||
                              (!_vm.validateForm && _vm.formSubmitted),
                          },
                          on: { click: _vm.createFreeTrialAccount },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("vue.createAccount")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }