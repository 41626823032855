var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-full sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4 mb-base",
      },
      [
        _c("vs-card", { staticClass: "cardx" }, [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "vs-alert",
                {
                  attrs: {
                    color: "primary",
                    title: _vm.company.name,
                    active: "true",
                  },
                },
                [_vm._v("\n          " + _vm._s(_vm.companyId) + "\n        ")]
              ),
            ],
            1
          ),
          _c("div", [
            _c("div", { staticClass: "mollie-checkout__invoice__wrapper" }, [
              _c(
                "div",
                { staticClass: "mollie-checkout__invoice__wrapper__content" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mollie-checkout__invoice__wrapper__content__form",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mollie-checkout__invoice__wrapper__content__form__fields",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__flex",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("datepicker", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "invoiceBillDate",
                                        attrs: {
                                          inline: false,
                                          name: _vm.$t("vue.invoiceBillDate"),
                                          disabledDates: {
                                            to: new Date(Date.now() - 8640000),
                                          },
                                          format: "yyyy-MM-dd",
                                          "clear-button": true,
                                          "monday-first": true,
                                          "wrapper-class":
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                          "clear-button-icon": "fa fa-times",
                                        },
                                        on: {
                                          opened: function ($event) {
                                            _vm.isOpenDatePicker = true
                                          },
                                          closed: function ($event) {
                                            _vm.isOpenDatePicker = false
                                          },
                                        },
                                        model: {
                                          value: _vm.invoice.invoiceBillDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoice,
                                              "invoiceBillDate",
                                              $$v
                                            )
                                          },
                                          expression: "invoice.invoiceBillDate",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              !!_vm.invoice.invoiceBillDate ||
                                              _vm.isOpenDatePicker,
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--over":
                                              _vm.isOpenDatePicker,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.invoiceBillDate.$el.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.$t("vue.invoiceBillDate")
                                              ) +
                                              " *\n                      "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.errors.first(
                                    _vm.$t("vue.invoiceBillDate")
                                  )
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("vue.invoiceBillDate")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ml-2 mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass: "inline-flex",
                                          attrs: {
                                            text: _vm.$t(
                                              "vue.generateInvoiceAmountMessage"
                                            ),
                                            position: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-switch",
                                            {
                                              attrs: {
                                                color: "success",
                                                disabled:
                                                  !_vm.selectedPlan ||
                                                  (_vm.selectedPlan &&
                                                    _vm.selectedPlan
                                                      .billing_type ===
                                                      "month"),
                                              },
                                              model: {
                                                value:
                                                  _vm.GENERATE_NEXT_INVOICE_DATE_BY_PLAN,
                                                callback: function ($$v) {
                                                  _vm.GENERATE_NEXT_INVOICE_DATE_BY_PLAN =
                                                    $$v
                                                },
                                                expression:
                                                  "GENERATE_NEXT_INVOICE_DATE_BY_PLAN",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "on" },
                                                  slot: "on",
                                                },
                                                [_vm._v("year")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "off" },
                                                  slot: "off",
                                                },
                                                [_vm._v("month")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.invoice.selectedPlanId
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ml-2 mollie-checkout__invoice__wrapper__content__form__fields__field",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                        },
                                        [
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "inline-flex",
                                              attrs: {
                                                text: _vm.$t(
                                                  "vue.totalAmountExcludingTax"
                                                ),
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "vs-chip",
                                                { attrs: { color: "success" } },
                                                [
                                                  _c("vs-avatar", {
                                                    attrs: { text: "€" },
                                                  }),
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm.selectedPlanPrice
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field mt-5",
                              staticStyle: { "max-width": "179px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c(
                                    "multiselect",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      ref: "title",
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__select mollie-checkout__invoice__wrapper__content__form__fields__field__select--small-min-height",
                                      attrs: {
                                        options: _vm.titles,
                                        multiple: false,
                                        "close-on-select": true,
                                        "clear-on-select": false,
                                        placeholder: "",
                                        selectLabel: "",
                                        selectedLabel: "",
                                        deselectLabel: "",
                                        label: "name",
                                        "track-by": "name",
                                        name: _vm.$t("inputs.salutation"),
                                        "open-direction": "bottom",
                                        "data-vv-as":
                                          _vm.$t("inputs.salutation"),
                                        "preserve-search": true,
                                        "preselect-first": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          _vm.isOpenTitleSelect = false
                                        },
                                        open: function ($event) {
                                          _vm.isOpenTitleSelect = true
                                        },
                                      },
                                      model: {
                                        value: _vm.invoice.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.invoice, "title", $$v)
                                        },
                                        expression: "invoice.title",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "noResult" }, [
                                        _vm._v("No results found"),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          !!_vm.invoice.title ||
                                          _vm.isOpenTitleSelect,
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--over":
                                          _vm.isOpenTitleSelect,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.title.$el.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.$t("inputs.salutation")) +
                                          " *\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.errors.first(_vm.$t("inputs.salutation"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.salutation")
                                          )
                                        ) + "\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c(
                                    "multiselect",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      ref: "selectedPlan",
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__select mollie-checkout__invoice__wrapper__content__form__fields__field__select--small-min-height",
                                      attrs: {
                                        options: _vm.plans,
                                        multiple: false,
                                        "close-on-select": true,
                                        "clear-on-select": false,
                                        placeholder: "",
                                        selectLabel: "",
                                        selectedLabel: "",
                                        deselectLabel: "",
                                        label: "name",
                                        "track-by": "id",
                                        name: "choose-plan",
                                        "open-direction": "bottom",
                                        "data-vv-as": "choose-plan",
                                        "preserve-search": true,
                                        "preselect-first": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          _vm.isOpenPlanSelect = false
                                        },
                                        open: function ($event) {
                                          _vm.isOpenPlanSelect = true
                                        },
                                      },
                                      model: {
                                        value: _vm.selectedPlan,
                                        callback: function ($$v) {
                                          _vm.selectedPlan = $$v
                                        },
                                        expression: "selectedPlan",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "noResult" }, [
                                        _vm._v("No results found"),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          !!_vm.selectedPlan ||
                                          _vm.isOpenPlanSelect,
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--over":
                                          _vm.isOpenPlanSelect,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.title.$el.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.$t("vue.choosePlan")) +
                                          " *\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first("choose-plan")
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first("choose-plan")
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.selectedPlan
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.customPlanPrice,
                                            expression: "customPlanPrice",
                                          },
                                        ],
                                        ref: "customPlanPrice",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "number",
                                          "label-placeholder": _vm.$t(
                                            "inputs.customPlanPrice"
                                          ),
                                        },
                                        domProps: {
                                          value: _vm.customPlanPrice,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.customPlanPrice =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.customPlanPrice &&
                                              _vm.customPlanPrice.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.customPlanPrice.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t("inputs.customPlanPrice")
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.invoice.academicTitle,
                                        expression: "invoice.academicTitle",
                                      },
                                    ],
                                    ref: "academicTitle",
                                    staticClass:
                                      "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                    attrs: {
                                      type: "text",
                                      name: _vm.$t("inputs.academicTitle"),
                                      "label-placeholder": _vm.$t(
                                        "inputs.academicTitle"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.invoice.academicTitle,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.invoice,
                                          "academicTitle",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          _vm.invoice.academicTitle &&
                                          _vm.invoice.academicTitle.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.academicTitle.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t("inputs.academicTitle")
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fullname",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.invoice.firstName,
                                            expression: "invoice.firstName",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "firstName",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          "data-vv-as":
                                            _vm.$t("inputs.firstName"),
                                          name: _vm.$t("inputs.firstName"),
                                          "label-placeholder":
                                            _vm.$t("inputs.firstName"),
                                        },
                                        domProps: {
                                          value: _vm.invoice.firstName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.invoice,
                                              "firstName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.invoice.firstName &&
                                              _vm.invoice.firstName.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.firstName.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.$t("inputs.firstName")
                                              ) +
                                              " *\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(_vm.$t("inputs.firstName"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.firstName")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.invoice.lastName,
                                            expression: "invoice.lastName",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "lastName",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          "data-vv-as":
                                            _vm.$t("inputs.lastName"),
                                          name: _vm.$t("inputs.lastName"),
                                          "label-placeholder":
                                            _vm.$t("inputs.lastName"),
                                        },
                                        domProps: {
                                          value: _vm.invoice.lastName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.invoice,
                                              "lastName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.invoice.lastName &&
                                              _vm.invoice.lastName.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.lastName.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.$t("inputs.lastName")
                                              ) +
                                              " *\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(_vm.$t("inputs.lastName"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.lastName")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.invoice.company,
                                        expression: "invoice.company",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    ref: "company",
                                    staticClass:
                                      "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                    attrs: {
                                      type: "text",
                                      "data-vv-as": _vm.$t("inputs.company"),
                                      name: _vm.$t("inputs.company"),
                                      "label-placeholder":
                                        _vm.$t("inputs.company"),
                                    },
                                    domProps: { value: _vm.invoice.company },
                                    on: {
                                      change: function ($event) {
                                        _vm.$validator.validate(
                                          _vm.$t("inputs.vatNumber")
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.invoice,
                                          "company",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          _vm.invoice.company &&
                                          _vm.invoice.company.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.company.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.$t("inputs.company")) +
                                          " *\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.errors.first(_vm.$t("inputs.company"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.company")
                                          )
                                        ) + "\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.invoice.vatNumber,
                                        expression: "invoice.vatNumber",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "isvatvalid",
                                        expression: "'isvatvalid'",
                                      },
                                    ],
                                    ref: "vatNumber",
                                    staticClass:
                                      "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                    attrs: {
                                      type: "text",
                                      name: _vm.$t("inputs.vatNumber"),
                                      "label-placeholder":
                                        _vm.$t("inputs.vatNumber"),
                                      "data-vv-validate-on": "change",
                                    },
                                    domProps: { value: _vm.invoice.vatNumber },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.invoice,
                                          "vatNumber",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.errors.first(_vm.$t("inputs.vatNumber"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.vatNumber")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          _vm.invoice.vatNumber &&
                                          _vm.invoice.vatNumber.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.vatNumber.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.$t("inputs.vatNumber")) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.invoice.streetAddress,
                                        expression: "invoice.streetAddress",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    ref: "streetAddress",
                                    staticClass:
                                      "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                    attrs: {
                                      type: "text",
                                      "data-vv-as": _vm.$t(
                                        "inputs.streetAddress"
                                      ),
                                      name: _vm.$t("inputs.streetAddress"),
                                      "label-placeholder": _vm.$t(
                                        "inputs.streetAddress"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.invoice.streetAddress,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.invoice,
                                          "streetAddress",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          _vm.invoice.streetAddress &&
                                          _vm.invoice.streetAddress.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.streetAddress.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t("inputs.streetAddress")
                                          ) +
                                          " *\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.errors.first(_vm.$t("inputs.streetAddress"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.streetAddress")
                                          )
                                        ) + "\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__equal-parts",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.invoice.city,
                                            expression: "invoice.city",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "city",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          "data-vv-as": _vm.$t("inputs.city"),
                                          name: _vm.$t("inputs.city"),
                                          "label-placeholder":
                                            _vm.$t("inputs.city"),
                                        },
                                        domProps: { value: _vm.invoice.city },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.invoice,
                                              "city",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.invoice.city &&
                                              _vm.invoice.city.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.city.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.$t("inputs.city")) +
                                              " *\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(_vm.$t("inputs.city"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.city")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.invoice.zipOrPostalCode,
                                            expression:
                                              "invoice.zipOrPostalCode",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "zipOrPostalCode",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          "data-vv-as": _vm.$t(
                                            "inputs.zipOrPostalCode"
                                          ),
                                          name: _vm.$t(
                                            "inputs.zipOrPostalCode"
                                          ),
                                          "label-placeholder": _vm.$t(
                                            "inputs.zipOrPostalCode"
                                          ),
                                        },
                                        domProps: {
                                          value: _vm.invoice.zipOrPostalCode,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.invoice,
                                              "zipOrPostalCode",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.invoice.zipOrPostalCode &&
                                              _vm.invoice.zipOrPostalCode
                                                .length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.zipOrPostalCode.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.$t("inputs.zipOrPostalCode")
                                              ) +
                                              " *\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(
                                    _vm.$t("inputs.zipOrPostalCode")
                                  )
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.zipOrPostalCode")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c(
                                    "multiselect",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      ref: "country",
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__select",
                                      attrs: {
                                        options: _vm.countryCodes,
                                        multiple: false,
                                        "close-on-select": true,
                                        "clear-on-select": false,
                                        placeholder: "",
                                        selectLabel: "",
                                        selectedLabel: "",
                                        deselectLabel: "",
                                        label: "countryName",
                                        "track-by": "name",
                                        name: _vm.$t("inputs.country"),
                                        "open-direction": "bottom",
                                        "data-vv-as": _vm.$t("inputs.country"),
                                        "preserve-search": true,
                                        "preselect-first": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          _vm.isOpenCountrySelect = false
                                        },
                                        open: function ($event) {
                                          _vm.isOpenCountrySelect = true
                                        },
                                      },
                                      model: {
                                        value: _vm.invoice.country,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.invoice, "country", $$v)
                                        },
                                        expression: "invoice.country",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "noResult" }, [
                                        _vm._v("No results found"),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          !!_vm.invoice.country ||
                                          _vm.isOpenCountrySelect,
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--over":
                                          _vm.isOpenCountrySelect,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.country.$el.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.$t("inputs.country")) +
                                          " *\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.errors.first(_vm.$t("inputs.country"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.country")
                                          )
                                        ) + "\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "vs-row",
                { attrs: { "vs-justify": "flex-end" } },
                [
                  _c("vs-button", {
                    staticClass: "mr-2",
                    attrs: { type: "gradient", color: "primary", icon: "save" },
                    on: { click: _vm.saveInvoice },
                  }),
                  _c("vs-button", {
                    staticClass: "mr-2",
                    attrs: { color: "primary", icon: "close" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-invoice")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }