var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
    _c(
      "div",
      { staticClass: "vx-col w-full lg:w-1/1 sm:w-1/1 mb-base" },
      [
        _c("vx-card", [
          _c(
            "div",
            { staticClass: "pt-2 pb-2 pr-2 pl-2", attrs: { align: "right" } },
            [
              _c("vs-button", {
                attrs: {
                  color: "primary",
                  type: "filled",
                  "icon-pack": "feather",
                  icon: "icon-x",
                },
                on: {
                  click: function ($event) {
                    return _vm.closeCompanyDetail()
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4 mb-base",
              },
              [
                _c("vx-card", { staticClass: "overflow-hidden dark-theme" }, [
                  _c("div", { staticClass: "p-6 dialog" }, [
                    _c("ul", { staticClass: "vx-timeline" }, [
                      _c("li", [
                        _c(
                          "div",
                          { staticClass: "timeline-icon bg-primary" },
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: "PlusIcon",
                                svgClasses: "text-white stroke-current w-5 h-5",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "timeline-info" }, [
                          _c("p", { staticClass: "font-semibold" }, [
                            _vm._v(_vm._s(_vm.$t("vue.name"))),
                          ]),
                          _c("span", { staticClass: "activity-desc" }, [
                            _vm._v(_vm._s(_vm.detail.company.name)),
                          ]),
                        ]),
                        _vm.detail.company.street || _vm.detail.company.city
                          ? _c("small", { staticClass: "activity-e-time" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.detail.company.street) +
                                  " " +
                                  _vm._s(_vm.detail.company.city) +
                                  "\n                  "
                              ),
                            ])
                          : _vm._e(),
                        _c("br"),
                        _c("small", { staticClass: "activity-e-time" }, [
                          _vm._v(
                            _vm._s(_vm.detail.company.zipcode) +
                              " " +
                              _vm._s(_vm.detail.company.country)
                          ),
                        ]),
                        _c("br"),
                      ]),
                      _c("li", [
                        _c(
                          "div",
                          { staticClass: "timeline-icon bg-warning" },
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: "LinkIcon",
                                svgClasses: "text-white stroke-current w-5 h-5",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "timeline-info" }, [
                          _c("p", { staticClass: "font-semibold" }, [
                            _vm._v(_vm._s(_vm.$t("vue.url"))),
                          ]),
                          _c("span", { staticClass: "activity-desc" }, [
                            _vm._v(_vm._s(_vm.detail.company.url)),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c(
                          "div",
                          { staticClass: "timeline-icon bg-danger" },
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: "PhoneIcon",
                                svgClasses: "text-white stroke-current w-5 h-5",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "timeline-info" }, [
                          _c("p", { staticClass: "font-semibold" }, [
                            _vm._v(_vm._s(_vm.$t("vue.phone"))),
                          ]),
                          _c("span", { staticClass: "activity-desc" }, [
                            _vm._v(_vm._s(_vm.detail.company.phone)),
                          ]),
                        ]),
                      ]),
                      _vm.detail.company.createdByUser
                        ? _c("li", [
                            _c(
                              "div",
                              { staticClass: "timeline-icon bg-danger" },
                              [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "UserIcon",
                                    svgClasses:
                                      "text-white stroke-current w-5 h-5",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "timeline-info" }, [
                              _c("p", { staticClass: "font-semibold" }, [
                                _vm._v(_vm._s(_vm.$t("vue.createdBy"))),
                              ]),
                              _c("span", { staticClass: "activity-desc" }, [
                                _vm._v(
                                  _vm._s(_vm.detail.company.createdByUser.name)
                                ),
                              ]),
                            ]),
                            _c("small", { staticClass: "activity-e-time" }, [
                              _vm._v(
                                _vm._s(_vm.detail.company.createdByUser.email)
                              ),
                            ]),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _c("li", [
                        _c(
                          "div",
                          { staticClass: "timeline-icon bg-danger" },
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: "FlagIcon",
                                svgClasses: "text-white stroke-current w-5 h-5",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.detail.company.utm_source
                          ? _c(
                              "div",
                              { staticClass: "timeline-info" },
                              [
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v("utm"),
                                ]),
                                _c("social-icons", {
                                  attrs: {
                                    name: _vm.getUtmSource(
                                      _vm.detail.company.utm_source
                                    ),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.detail.company.utm_campaign
                          ? _c("div", { staticClass: "timeline-info" }, [
                              _c("p", { staticClass: "font-semibold" }, [
                                _vm._v(_vm._s(_vm.detail.company.utm_campaign)),
                              ]),
                              _c("p", [
                                _vm.detail.company.utm_medium
                                  ? _c(
                                      "small",
                                      { staticClass: "activity-e-time" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.detail.company.utm_medium
                                          ) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("p", [
                                _vm.detail.company.utm_term
                                  ? _c(
                                      "small",
                                      { staticClass: "activity-e-time" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.detail.company.utm_term) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            !_vm.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4 mb-base",
                  },
                  [
                    _c(
                      "vx-card",
                      { staticClass: "overflow-hidden dark-theme" },
                      [
                        _vm.detail.affiliateCompany
                          ? _c("div", { staticClass: "p-6 dialog" }, [
                              _vm.detail.affiliateCompany
                                ? _c("ul", { staticClass: "vx-timeline" }, [
                                    _c("li", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "timeline-icon bg-primary",
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "PlusIcon",
                                              svgClasses:
                                                "text-white stroke-current w-5 h-5",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "timeline-info" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "font-semibold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("vue.affiliateCompany")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "activity-desc" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.affiliateCompany
                                                    .name
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "activity-e-time" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.detail.affiliateCompany.street
                                            ) +
                                              " " +
                                              _vm._s(
                                                _vm.detail.affiliateCompany.city
                                              )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _c(
                                        "small",
                                        { staticClass: "activity-e-time" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.detail.affiliateCompany
                                                .zipcode
                                            ) +
                                              " " +
                                              _vm._s(
                                                _vm.detail.affiliateCompany
                                                  .country
                                              )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "timeline-icon bg-warning",
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "LinkIcon",
                                              svgClasses:
                                                "text-white stroke-current w-5 h-5",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "timeline-info" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "font-semibold" },
                                            [_vm._v(_vm._s(_vm.$t("vue.url")))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "activity-desc" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.affiliateCompany
                                                    .url
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "timeline-icon bg-danger",
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "PhoneIcon",
                                              svgClasses:
                                                "text-white stroke-current w-5 h-5",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "timeline-info" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "font-semibold" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("vue.phone"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "activity-desc" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.affiliateCompany
                                                    .phone
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm.detail.affiliateCompany.createdByUser
                                      ? _c("li", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "timeline-icon bg-danger",
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  icon: "UserIcon",
                                                  svgClasses:
                                                    "text-white stroke-current w-5 h-5",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "timeline-info" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "font-semibold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("vue.createdBy")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "activity-desc",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.detail
                                                        .affiliateCompany
                                                        .createdByUser.name
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "activity-e-time" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detail.affiliateCompany
                                                    .createdByUser.email
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _c("div", { staticClass: "p-6 dialog" }, [
                              _c("h2", [_vm._v("No Affiliate")]),
                            ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }