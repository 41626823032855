var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.source === "google"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 48 48",
              width: "20px",
              height: "20px",
            },
          },
          [
            _c("path", {
              attrs: {
                fill: "#fbc02d",
                d: "M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z",
              },
            }),
            _c("path", {
              attrs: {
                fill: "#e53935",
                d: "M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z",
              },
            }),
            _c("path", {
              attrs: {
                fill: "#4caf50",
                d: "M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z",
              },
            }),
            _c("path", {
              attrs: {
                fill: "#1565c0",
                d: "M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.source === "bing"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 48 48",
              width: "20px",
              height: "20px",
            },
          },
          [
            _c("path", {
              attrs: {
                fill: "#6563ff",
                d: "M9 5L9 37 18 43 39 32 39 21 22 15 25 24 31 26 11 36 19 29 19 8z",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.source === "direct"
      ? _c(
          "svg",
          {
            attrs: {
              width: "20",
              height: "20",
              viewBox: "0 0 20 20",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("rect", {
              attrs: { width: "20", height: "20", rx: "3", fill: "#F7F7F7" },
            }),
            _c(
              "mask",
              {
                staticStyle: { "mask-type": "alpha" },
                attrs: {
                  id: "mask0_5043_6",
                  maskUnits: "userSpaceOnUse",
                  x: "0",
                  y: "0",
                  width: "20",
                  height: "20",
                },
              },
              [
                _c("rect", {
                  attrs: {
                    width: "20",
                    height: "20",
                    rx: "3",
                    fill: "#F7F7F7",
                  },
                }),
              ]
            ),
            _c("g", { attrs: { mask: "url(#mask0_5043_6)" } }, [
              _c("path", {
                attrs: {
                  d: "M9.50682 5.73368L19.7377 4.24021C19.8741 4.21932 20 4.09533 20 3.97V0.27C20 0.123786 19.8531 0 19.6957 0H0.272823C0.125918 0 0 0.114883 0 0.271541V19.7285C0 19.8747 0.115425 20 0.272823 20H1.98321C2.13012 20 2.25603 19.8851 2.25603 19.7285C2.25603 19.5718 2.38195 19.4569 2.52886 19.4569H3.2319C3.3788 19.4569 3.49423 19.342 3.50472 19.2063L3.64113 17.2637C3.65163 17.1175 3.76705 17.0131 3.91396 17.0131H8.6149C8.76181 17.0131 8.87723 17.1279 8.88772 17.2637L9.02414 19.2063C9.03463 19.3525 9.15005 19.4569 9.29696 19.4569H9.75866C9.90556 19.4569 10.0315 19.5718 10.0315 19.7285C10.0315 19.8851 10.1574 20 10.3043 20H19.7272C19.8741 20 20 19.8851 20 19.7285V9.48303C20 9.3577 19.9161 9.25326 19.8006 9.22193L9.47534 6.26632C9.19203 6.18277 9.21301 5.77546 9.50682 5.73368ZM4.48059 4.37598L5.97062 3.78068V3.63447H6.149V3.54047C6.04407 3.48825 5.97062 3.38381 5.97062 3.25849C5.97062 3.13316 6.05457 3.01828 6.16999 2.96606L6.22246 1.76501H6.33788L6.39035 2.98695C6.50577 3.02872 6.60021 3.1436 6.60021 3.27937C6.60021 3.4047 6.52676 3.50914 6.42183 3.56136L6.43232 3.65535H6.6107V3.80157C7.14586 4.01044 8.11123 4.39687 8.11123 4.39687V4.91906H4.48059V4.37598ZM4.47009 6.76762H4.81637V5.15927H7.64953V6.75718H8.02728L8.21616 7.59269H4.28122L4.47009 6.76762ZM4.27072 7.93734H5.63484H6.4638H8.21616L8.3106 9.39948H4.17629L4.27072 7.93734ZM4.08185 10.5692H8.40504L8.53096 12.47H3.95593L4.08185 10.5692ZM3.73557 15.6449L3.87198 13.7128H8.62539L8.76181 15.6449H3.73557Z",
                  fill: "#12598D",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M6.63169 5.38867H5.94963V6.76726H6.63169V5.38867Z",
                  fill: "#12598D",
                },
              }),
            ]),
          ]
        )
      : _vm._e(),
    _vm.source === "facebook"
      ? _c(
          "svg",
          {
            attrs: {
              width: "20",
              height: "20",
              viewBox: "0 0 20 20",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("rect", {
              attrs: { width: "20", height: "20", rx: "3", fill: "#1877F2" },
            }),
            _c("path", {
              attrs: {
                d: "M13.5 4H11.5909C10.747 4 9.93773 4.31607 9.34102 4.87868C8.74432 5.44129 8.40909 6.20435 8.40909 7V8.8H6.5V11.2H8.40909V16H10.9545V11.2H12.8636L13.5 8.8H10.9545V7C10.9545 6.84087 11.0216 6.68826 11.1409 6.57574C11.2603 6.46321 11.4221 6.4 11.5909 6.4H13.5V4Z",
                fill: "white",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.source === "linkedin"
      ? _c(
          "svg",
          {
            attrs: {
              width: "20",
              height: "20",
              viewBox: "0 0 20 20",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("rect", {
              attrs: { width: "20", height: "20", rx: "3", fill: "#0072B1" },
            }),
            _c("path", {
              attrs: {
                d: "M12.5 8C13.5609 8 14.5783 8.38901 15.3284 9.08145C16.0786 9.77389 16.5 10.713 16.5 11.6923V16H13.8333V11.6923C13.8333 11.3659 13.6929 11.0528 13.4428 10.822C13.1928 10.5912 12.8536 10.4615 12.5 10.4615C12.1464 10.4615 11.8072 10.5912 11.5572 10.822C11.3071 11.0528 11.1667 11.3659 11.1667 11.6923V16H8.5V11.6923C8.5 10.713 8.92143 9.77389 9.67157 9.08145C10.4217 8.38901 11.4391 8 12.5 8V8Z",
                fill: "white",
              },
            }),
            _c("path", { attrs: { d: "M6.5 8H3.5V16H6.5V8Z", fill: "white" } }),
            _c("path", {
              attrs: {
                d: "M5.00098 7C5.8294 7 6.50098 6.32843 6.50098 5.5C6.50098 4.67157 5.8294 4 5.00098 4C4.17255 4 3.50098 4.67157 3.50098 5.5C3.50098 6.32843 4.17255 7 5.00098 7Z",
                fill: "white",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm.source === "mail"
      ? _c(
          "svg",
          {
            attrs: {
              width: "20",
              height: "20",
              viewBox: "0 0 20 20",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("rect", {
              attrs: { width: "20", height: "20", rx: "3", fill: "#E6ECF1" },
            }),
            _c("path", {
              attrs: {
                d: "M4.75056 5H14.7506C15.4381 5 16.0006 5.5625 16.0006 6.25V13.75C16.0006 14.4375 15.4381 15 14.7506 15H4.75056C4.06306 15 3.50056 14.4375 3.50056 13.75V6.25C3.50056 5.5625 4.06306 5 4.75056 5Z",
                stroke: "#12598D",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
            _c("path", {
              attrs: {
                d: "M16 6.25L9.75 10.625L3.5 6.25",
                stroke: "#12598D",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }