<template>
  <transition name="fade-bottom-2x flex">
    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
      <div class="flex justify-align-center">
        <vx-card class="add-coupon">
          <div id="coupon-container">
            <div class="pt-2 pb-2 pr-2 pl-2" align="right">
              <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-x" @click="closeCoupon()"></vs-button>
            </div>
            <div v-if="coupon">
              <div class="vx-row px-5 mt-5 mb-6">
                <h4><vs-icon icon-pack="feather" icon="icon-gift" size="20px" class="mr-2" />{{ $t('vue.couponCode') }}</h4>
                <prism class="w-full rounded-lg" language="javascript">{{ content }}</prism>
              </div>
              <div class="vx-row mb-8 px-5">
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-clipboard"
                  v-clipboard:copy="content"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  {{ $t('vue.copy') }}
                </vs-button>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <select v-validate="'required'" v-model="couponType" name="couponType" class="coupon-type w-full mb-6">
                    <option v-for="(item, index) in couponTypes" :key="index" :value="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="vx-row" v-if="couponType === 'expiry-date'">
                <div class="vx-col w-full mb-6">
                  <datepicker v-model="expiryDate" :inline="true" :disabledDates="{ to: new Date(Date.now() - 8640000) }"></datepicker>
                </div>
              </div>
              <div class="vx-row" v-if="couponType === 'expire-after-days'">
                <div class="vx-col w-full mb-6">
                  <vs-input type="number" v-model="expireAfterDays" style="width: 100%" :min="1" />
                </div>
              </div>
              <!-- <div class="vx-row" v-if="couponType === 'max-visitor-count'">
                <div class="vx-col w-full mb-6">
                  <vs-input type="number" v-model="maxVisitorCount" style="width: 100%" :min="1" />
                </div>
              </div> -->
              <div class="vx-row">
                <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4">
                  <vs-button :disabled="!isValid" @click="saveCoupon">{{ $t('vue.save') }}</vs-button>
                </div>
                <div class="vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4">
                  <vs-button class="ml-4" type="border" @click="closeCoupon">{{ $t('vue.cancel') }}</vs-button>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </transition>
</template>
<script>
import 'firebase/functions'
//import firebase from 'firebase/app'
import Prism from 'vue-prism-component'
import Datepicker from 'vuejs-datepicker'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  components: {
    Datepicker,
    Prism
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    isAffiliatePartner() {
      return this.coupon && this.coupon.affiliatePartner
    },
    affiliatePartnerLink() {
      return `https://pathadvice.at?tc=${this.coupon.affiliatePartner}&cc=${this.coupon.id}`
    },
    couponIdLink() {
      return `https://pathadvice.at?cc=${this.coupon.id}`
    },

    content() {
      return this.isAffiliatePartner ? this.affiliatePartnerLink : this.couponIdLink
    },
    isValid() {
      if (!this.coupon || !this.coupon.id) {
        return false
      }
      if (!this.couponType) {
        return false
      }
      if (this.couponType === 'expiry-date' && this.expiryDate === null) {
        return false
      }
      // if (this.couponType === 'max-visitor-count' && this.maxVisitorCount === null) {
      //   return false
      // }
      if (this.couponType === 'expire-after-days' && this.expireAfterDays === null) {
        return false
      }

      return true
    }
  },
  data() {
    return {
      coupon: null,
      couponType: null,
      expiryDate: null,
      maxVisitorCount: null,
      expireAfterDays: null,
      couponTypes: [
        { text: this.translate('vue.expiryDate'), value: 'expiry-date' },
        { text: this.translate('vue.expiryAfterDays'), value: 'expire-after-days' }
        //{ text: this.translate('vue.maxVisitorCount'), value: 'max-visitor-count' }
      ]
    }
  },
  async mounted() {
    const vm = this
    vm.$serverBus.$on('show-coupon-code', async (data) => {
      if (data.id) {
        vm.$vs.loading({
          container: '#coupon-container',
          type: 'point',
          scale: 0.45
        })
        const getCouponForCompany = vm.$functions.httpsCallable('getCouponForCompany')
        const res = await getCouponForCompany({ company: data.id, createdBy: vm.activeUserInfo.uid })
        if (res && res.data) {
          vm.coupon = res.data.coupon
          if (res.data.coupon && res.data.coupon.type) {
            vm.couponType = res.data.coupon.type
          }
          if (vm.couponType === 'expiry-date') {
            vm.expiryDate = dayjs.unix(res.data.coupon.expiryDate._seconds).toDate()
          }
          if (vm.couponType === 'max-visitor-count') {
            vm.maxVisitorCount = parseInt(res.data.coupon.maxVisitorCount) ? parseInt(res.data.coupon.maxVisitorCount) : null
          }
          if (vm.couponType === 'expire-after-days') {
            vm.expireAfterDays = parseInt(res.data.coupon.expireAfterDays) ? parseInt(res.data.coupon.expireAfterDays) : null
          }
        }

        vm.$vs.loading.close('#coupon-container > .con-vs-loading')
      }
    })
  },

  watch: {
    couponType() {
      if (this.couponType === 'expiry-date') {
        this.maxVisitorCount = null
        this.expireAfterDays = null
      }
      if (this.couponType === 'max-visitor-count') {
        this.expiryDate = null
        this.expireAfterDays = null
      }
      if (this.couponType === 'expire-after-days') {
        this.maxVisitorCount = null
        this.expiryDate = null
      }
    }
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    closeCoupon() {
      this.showCoupon = false
      this.$serverBus.$emit('close-coupon-code')
    },
    async saveCoupon() {
      const vm = this
      vm.$vs.loading({ container: '#coupon-container', type: 'point', scale: 0.45 })

      const coupon = {
        type: vm.couponType
      }
      if (vm.couponType === 'expiry-date') {
        coupon.expiryDate = {
          year: vm.expiryDate.getFullYear(),
          month: vm.expiryDate.getMonth(),
          date: vm.expiryDate.getDate()
        }
      }
      if (vm.couponType === 'max-visitor-count') {
        coupon.maxVisitorCount = parseInt(vm.maxVisitorCount)
      }
      if (vm.couponType === 'expire-after-days') {
        coupon.expireAfterDays = parseInt(vm.expireAfterDays)
      }
      const updateCoupon = vm.$functions.httpsCallable('updateCoupon')
      const res = await updateCoupon({ id: vm.coupon.id, coupon })
      if (res && res.data) {
        vm.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.success'),
          text: vm.$i18n.t('vue.changesSavedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
        vm.$vs.loading.close('#coupon-container > .con-vs-loading')
        vm.$serverBus.$emit('close-coupon-code')
      }
    },
    onCopy() {
      this.$vs.notify({
        title: this.translate('vue.success'),
        text: this.translate('vue.linkCopiedSuccessfully'),
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onError() {
      this.$vs.notify({
        title: this.translate('vue.error'),
        text: this.translate('vue.errorCopyingText'),
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    }
  }
}
</script>

<style>
.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}
.coupon-type {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px !important;
  position: relative;
  margin: 0;
}
.vdp-datepicker__calendar header .prev:not(.disabled),
.vdp-datepicker__calendar header .next:not(.disabled),
.vdp-datepicker__calendar header .up:not(.disabled) {
  background-color: rgba(59, 134, 247, 1) !important;
  color: white;
}

.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover {
  background-color: rgba(59, 134, 247, 0.9) !important;
}
.vdp-datepicker__calendar header .next:after {
  border-left: 10px solid #fff;
}
.vdp-datepicker__calendar header .prev:after {
  border-right: 10px solid #fff;
}
.add-coupon {
  max-width: 720px;
}
.vdp-datepicker__calendar {
  width: 457px !important;
}
.justify-align-center {
  align-items: center;
  justify-content: center;
}
</style>
