<template>
  <transition name="fade-bottom-2x">
    <div class="vx-col w-full sm:w-2/3 md:w-1/2 lg:w-2/3 xl:w-2/3 mb-base">
      <vs-popup title="Extend Trial Period" :active.sync="showExtendTrialPeriod" width="800px">
        <p class="mb-2">
          {{ $t('vue.confirmQuestion') }}
        </p>
        <div class="trial-container">
          <div class="trial-container-info">
            <div class="trial-container-info-header">Current</div>
            <div>
              Payment Status: <span class="trial-container-info-current">{{ currentPaymentStatus }}</span>
            </div>
            <div>
              Plan Type: <span class="trial-container-info-current">{{ currentPlanType }}</span>
            </div>
            <div>
              Subscription Plan Id: <span class="trial-container-info-current">{{ currentSubscriptonPlanId }}</span>
            </div>
            <div>
              Expiry Date: <span class="trial-container-info-current">{{ currentExpiryDate }}</span>
            </div>
            <div>
              Claims: <span class="trial-container-info-current">{{ currentClaims }}</span>
            </div>
          </div>
          <div class="trial-container-info">
            <div class="trial-container-info-header">New</div>
            <div>
              Payment Status: <span class="trial-container-info-new">{{ newPaymentStatus }}</span>
            </div>
            <div>
              Plan Type: <span class="trial-container-info-new">{{ newPlanType }}</span>
            </div>
            <div>
              Subscription Plan Id: <span class="trial-container-info-new">{{ newSubscriptonPlanId }}</span>
            </div>
            <div>
              Expiry Date: <span class="trial-container-info-new">{{ newExpiryDate }}</span>
            </div>
            <div>
              Claims: <span class="trial-container-info-new">{{ newTrialClaims }}</span>
            </div>
          </div>
        </div>
        <vs-button @click="onExtendTrialPeriod" color="#e8546f" type="filled" class="mr-2 mt-2">{{ $t('vue.confirm') }}</vs-button>
        <vs-button @click="showExtendTrialPeriod = false" type="border" color="#e8546f">{{ $t('vue.cancel') }}</vs-button>
      </vs-popup>
      <vs-popup title="Extend Trial Period" :active.sync="showExtendVegaTrialPeriod" width="800px">
        <p class="mb-2">
          {{ $t('vue.confirmQuestion') }}
        </p>
        <div class="trial-container">
          <div class="trial-container-info">
            <div class="trial-container-info-header">Current</div>
            <div>
              Expiry Date: <span class="trial-container-info-current">{{ currentVegaExpiryDate }}</span>
            </div>
          </div>
          <div class="trial-container-info">
            <div class="trial-container-info-header">New</div>

            <div>
              Expiry Date: <span class="trial-container-info-new">{{ newVegaExpiryDate }}</span>
            </div>
          </div>
        </div>
        <vs-button @click="onExtendVegaTrialPeriod" color="#e8546f" type="filled" class="mr-2 mt-2">{{ $t('vue.confirm') }}</vs-button>
        <vs-button @click="showExtendVegaTrialPeriod = false" type="border" color="#e8546f">{{ $t('vue.cancel') }}</vs-button>
      </vs-popup>
      <vs-card class="cardx" fixedHeight>
        <div slot="header" v-if="company">
          <h3>{{ company.name }} ({{ companyId }})</h3>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card class="overflow-hidden dark-theme" v-if="company">
              <ul class="centerx">
                <li v-for="(claim, index) in selectedCustomClaims" :key="index">
                  <vs-checkbox icon="flash_on" v-model="claim.selected">{{ claim.name }}</vs-checkbox>
                </li>
              </ul>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card class="overflow-hidden dark-theme" v-if="company">
              <h4>Select total users</h4>
              <vs-input v-model="maxUsers" type="number" class="w-75" />
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card class="overflow-hidden dark-theme" v-if="company">
              <h4>Extend Trial Period By days</h4>
              <div class="flex justify-start mt-2">
                <div class="mr-2"><vs-input v-model="extendTrialPeriodByDays" type="number" class="w-75" /></div>
                <div><vs-button @click="showExtendTrialPeriod = true">Extend Trial Period</vs-button></div>
              </div>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card class="overflow-hidden dark-theme" v-if="company">
              <h4>Extend Vega Trial Period By days</h4>
              <div class="flex justify-start mt-2">
                <div class="mr-2"><vs-input :disabled="!isVegaOnTrial" v-model="extendVegaTrialPeriodByDays" type="number" class="w-75" /></div>
                <div><vs-button :disabled="!isVegaOnTrial" @click="showExtendVegaTrialPeriod = true">Extend Trial Period</vs-button></div>
              </div>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card class="overflow-hidden dark-theme" v-if="company">
              <h3>Is Vega Account</h3>
              <p>Check/Uncheck to save it automatically</p>
              <div class="flex justify-start mt-2">
                <vs-checkbox icon="flash_on" @change="onUpdateIsVegaAccount" v-model="isVegaAccount">
                  <vs-chip size="small" color="primary" v-if="isVegaAccount"><vs-avatar icon="icon-check" icon-pack="feather" />Yes </vs-chip>
                  <vs-chip size="small" v-else color="danger"> <vs-avatar icon="icon-x" icon-pack="feather" />No </vs-chip>
                </vs-checkbox>
              </div>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card class="overflow-hidden dark-theme" v-if="company">
              <div class="flex">
                <div class="mr-10">
                  <h3>Managed Service</h3>
                  <p>Check/Uncheck to save it automatically</p>
                  <div class="flex justify-start mt-2">
                    <vs-checkbox icon="flash_on" @change="onUpdateCallCenter" v-model="isCallCenter">
                      <vs-chip size="small" color="primary" v-if="isCallCenter"><vs-avatar icon="icon-check" icon-pack="feather" />Yes </vs-chip>
                      <vs-chip size="small" v-else color="danger"> <vs-avatar icon="icon-x" icon-pack="feather" />No </vs-chip>
                    </vs-checkbox>
                  </div>
                </div>
                <div>
                  <h3>Managed Service Customer</h3>
                  <p>Check/Uncheck to save it automatically</p>
                  <div class="flex justify-start mt-2">
                    <vs-checkbox icon="flash_on" @change="onUpdateCallCenterCustomer" v-model="isCallCenterCustomer">
                      <vs-chip size="small" color="primary" v-if="isCallCenterCustomer"><vs-avatar icon="icon-check" icon-pack="feather" />Yes </vs-chip>
                      <vs-chip size="small" v-else color="danger"> <vs-avatar icon="icon-x" icon-pack="feather" />No </vs-chip>
                    </vs-checkbox>
                  </div>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button type="gradient" class="mr-2" color="danger" icon="save" @click="saveCustomClaims"></vs-button>
            <vs-button color="primary" class="mr-2" icon="close" @click="$emit('close-custom-claims')"></vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div>
  </transition>
</template>
<script>
import { paddle } from '@/mixins/paddle'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export default {
  mixins: [paddle],
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      company: null,
      isVegaAccount: false,
      isCallCenter: false,
      isCallCenterCustomer: false,
      plan_features: {
        free: ['dialog', 'video-audio-chat', 'dashboard-lite', 'free-email-support', 'virtual-background'],
        basic: ['visitors', 'contact-form', 'call-center', 'user-groups', 'api-keys', 'screen-sharing', 'vega'],
        professional: ['ab-testing', 'scheduling', 'b2b-identification', 'qr-code', 'support-chat', 'online-meeting'],
        enterprise: ['dashboard', 'routing'],
        custom: ['white-label']
      },
      selectedCustomClaims: [
        { name: 'dialog', selected: false },
        { name: 'video-audio-chat', selected: false },
        { name: 'contact-form', selected: false },
        { name: 'scheduling', selected: false },
        { name: 'user-groups', selected: false },
        { name: 'api-keys', selected: false },
        { name: 'visitors', selected: false },
        { name: 'dashboard-lite', selected: false },
        { name: 'free-email-support', selected: false },
        { name: 'virtual-background', selected: false },
        { name: 'online-meeting', selected: false },
        { name: 'call-center', selected: false },
        { name: 'white-label', selected: false },
        { name: 'b2b-identification', selected: false },
        { name: 'qr-code', selected: false },
        { name: 'support-chat', selected: false },
        { name: 'co-browsing', selected: false },
        { name: 'ab-testing', selected: false },
        { name: 'dashboard', selected: false },
        { name: 'routing', selected: false },
        { name: 'screen-sharing', selected: false },
        { name: 'vega', selected: false }
      ],
      totalUsersList: [1, 5, 10],
      maxUsers: 1,
      extendTrialPeriodByDays: 14,
      extendVegaTrialPeriodByDays: 14,
      showExtendTrialPeriod: false,
      showExtendVegaTrialPeriod: false
    }
  },
  async mounted() {
    const companyDoc = await this.$db.collection('company').doc(this.companyId).get()
    this.company = companyDoc.data()

    this.isVegaAccount = Boolean(this.company.isVegaAccount)
    this.isCallCenter = Boolean(this.company.isCallCenter)
    this.isCallCenterCustomer = Boolean(this.company.isCallCenterCustomer)

    if (this.company && this.company.claims_custom) {
      this.selectedCustomClaims.forEach((x) => {
        x.selected = this.company.claims_custom.includes(x.name)
      })
    }
    this.maxUsers = this.totalUsersForPlan || 5
  },
  computed: {
    totalUsersForPlan() {
      if (!this.company) {
        return 1
      }
      return this.company.totalUsersForPlan || this.getTotalUsersForSubscriptionPlan(this.company.subscriptionPlanId)
    },
    currentPaymentStatus() {
      if (!this.company || !this.company.paymentStatus) {
        return 'NO Payment Status'
      }
      return this.company.paymentStatus
    },
    currentPlanType() {
      if (!this.company || !this.company.planType) {
        return 'NO Plan'
      }
      return this.company.planType
    },
    currentSubscriptonPlanId() {
      if (!this.company || !this.company.subscriptionPlanId) {
        return 'No Subscription'
      }
      return this.company.subscriptionPlanId
    },
    currentExpiryDate() {
      if (!this.company || !this.company.expiryDate) {
        return 'NO Expiry Date'
      }
      return dayjs.unix(this.company.expiryDate.seconds).format('LLLL')
    },
    currentVegaExpiryDate() {
      if (!this.company || !this.company.vegaExpiryDate) {
        return 'NO Vega Expiry Date'
      }
      return dayjs.unix(this.company.vegaExpiryDate.seconds).format('LLLL')
    },
    currentClaims() {
      if (!this.company || !this.company.claims) {
        return 'No Claims'
      }
      return JSON.stringify(this.company.claims)
    },
    newPaymentStatus() {
      return 'on-trial'
    },
    newPlanType() {
      return 'NO Plan'
    },
    newSubscriptonPlanId() {
      return 'No Subscription'
    },
    newExpiryDate() {
      return dayjs().utc().add(this.extendTrialPeriodByDays, 'day').format('LLLL')
    },
    newVegaExpiryDate() {
      return dayjs().utc().add(this.extendVegaTrialPeriodByDays, 'day').format('LLLL')
    },
    newTrialClaims() {
      return JSON.stringify(this.plan_features.enterprise.concat(this.plan_features.free, this.plan_features.basic, this.plan_features.professional))
    },
    isVegaOnTrial() {
      if (!this.company || !this.company.vegaIsOnTrial) return false
      return true
    }
  },
  methods: {
    async saveCustomClaims() {
      this.$vs.loading()
      let claims = []
      switch (this.company.planType) {
        case 'free':
          claims = this.plan_features.free
          break

        case 'basic':
          claims = this.plan_features.basic.concat(this.plan_features.free)
          break

        case 'professional':
          claims = this.plan_features.professional.concat(this.plan_features.free, this.plan_features.basic)
          break

        case 'enterprise':
          claims = this.plan_features.enterprise.concat(this.plan_features.free, this.plan_features.basic, this.plan_features.professional)
          break
      }
      if (claims.length === 0 && this.company.claims && this.company.claims.length > 0) {
        claims = [...new Set(claims.concat(this.company.claims))]
      }

      const custom_claims = this.selectedCustomClaims.filter((x) => x.selected).map((x) => x.name)
      const new_claims = [...new Set(claims.concat(custom_claims))]

      const _claimsData = {
        claims_custom: custom_claims,
        claims: new_claims
      }

      if (this.maxUsers) {
        _claimsData.totalUsersForPlan = this.maxUsers
      }

      await this.$db.collection('company').doc(this.companyId).set(_claimsData, { merge: true })
      setTimeout(() => {
        this.$vs.loading.close()
        this.$emit('close-custom-claims')
      }, 500)
    },

    async onExtendTrialPeriod() {
      await this.$vs.loading()
      const claims = this.plan_features.enterprise.concat(this.plan_features.free, this.plan_features.basic, this.plan_features.professional)
      const _claimsData = {
        claims,
        paymentStatus: 'on-trial',
        planType: this.$firebase.firestore.FieldValue.delete(),
        subscriptionPlanId: this.$firebase.firestore.FieldValue.delete(),
        expiryDate: dayjs().utc().add(this.extendTrialPeriodByDays, 'day').toDate()
      }

      await this.$db.collection('company').doc(this.companyId).set(_claimsData, { merge: true })
      this.showExtendTrialPeriod = false
      await this.$vs.loading.close()
    },

    async onExtendVegaTrialPeriod() {
      await this.$vs.loading()
      const _claimsData = {
        vegaIsOnTrial: true,
        vegaExpiryDate: dayjs().utc().add(this.extendVegaTrialPeriodByDays, 'day').toDate()
      }
      await this.$db.collection('company').doc(this.companyId).set(_claimsData, { merge: true })
      this.showExtendVegaTrialPeriod = false
      await this.$vs.loading.close()
    },

    async onUpdateIsVegaAccount() {
      await this.$vs.loading()
      await this.$db.collection('company').doc(this.companyId).set({ isVegaAccount: this.isVegaAccount }, { merge: true })
      await this.$vs.loading.close()
    },

    async onUpdateCallCenter() {
      await this.$vs.loading()
      await this.$db.collection('company').doc(this.companyId).set({ isCallCenter: this.isCallCenter }, { merge: true })
      await this.$vs.loading.close()
    },

    async onUpdateCallCenterCustomer() {
      await this.$vs.loading()
      await this.$db.collection('company').doc(this.companyId).set({ isCallCenterCustomer: this.isCallCenterCustomer }, { merge: true })
      await this.$vs.loading.close()
    }
  }
}
</script>

<style lang="scss">
.con-vs-popup .vs-popup {
  width: 1024px !important;
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/components/vxTimeline.scss';

.trial-container {
  display: flex;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 23px;
  > * {
    flex: 1;
  }
  &-info {
    &-current {
      color: green;
      font-weight: bolder;
    }
    &-new {
      color: red;
      font-weight: bolder;
    }

    display: flex;

    flex-direction: column;
    flex: 1;
    padding: 10px;
    background-color: #ccc;
    border-radius: 6px;

    margin-right: 2px;
    margin-left: 2px;

    > div {
      margin-bottom: 5px;
    }

    &-header {
      font-weight: 700;
    }
  }
}

.w-75 {
  width: 75px !important;
}

.cardx {
  padding: 10px;
}
.centerx {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 1.8rem;
  li {
    list-style: none !important;
    display: inline-block;
    padding: 10px 2px 10px 2px;
  }
}

.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}
.dark-theme {
  background: rgba(59, 134, 247, 0.2);
}
</style>
